import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-items-action',
  templateUrl: './gallery-items-action.component.html',
  styleUrls: ['./gallery-items-action.component.scss']
})
export class GalleryItemsActionComponent implements OnInit {

  photos = [
    {
      url: '../../assets/img/radna-akcija/radna-akcija.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0000.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0001.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0002.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0003.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0005.jpg'
    },
    {
      url: '../../assets/img/radna-akcija/IMG-20190214-WA0006.jpg'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
