import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-items-meetings',
  templateUrl: './gallery-items-meetings.component.html',
  styleUrls: ['./gallery-items-meetings.component.scss']
})
export class GalleryItemsMeetingsComponent implements OnInit {

  photos = [
    {
      url: '../../assets/img/sastanci/atar.jpg'
    },
    {
      url: '../../assets/img/sastanci/atar2.jpg'
    },
    {
      url: '../../assets/img/sastanci/atar3.jpg'
    },
    {
      url: '../../assets/img/sastanci/atar4.jpg'
    },
    {
      url: '../../assets/img/sastanci/atar5.jpg'
    },
    {
      url: '../../assets/img/sastanci/atar6.jpg'
    }
  ];

  photosMeetings = [
    {
      url: '../../assets/img/sastanci/sastanak.jpg'
    },
    {
      url: '../../assets/img/sastanci/sastanak2.jpg'
    }
  ];

  photosHappy = [
    {
      url: '../../assets/img/sastanci/happy/happy1.jpg'
    },
    {
      url: '../../assets/img/sastanci/happy/happy2.jpg'
    },
    {
      url: '../../assets/img/sastanci/happy/happy3.jpg'
    },
    {
      url: '../../assets/img/sastanci/happy/happy4.jpg'
    },
    {
      url: '../../assets/img/sastanci/happy/happy5.jpg'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
