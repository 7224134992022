import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-items',
  templateUrl: './gallery-items.component.html',
  styleUrls: ['./gallery-items.component.scss']
})
export class GalleryItemsComponent implements OnInit {

  photos = [
    {
      url: '../../assets/img/dani/takmicenje.jpg'
    },
    {
      url: '../../assets/img/dani/2-1.jpg'
    },
    {
      url: '../../assets/img/dani/3-1.jpg'
    },
    {
      url: '../../assets/img/dani/8-1-817x675.jpg'
    },
    {
      url: '../../assets/img/dani/donacija.jpg'
    },
    {
      url: '../../assets/img/dani/krav3.jpg'
    },
    {
      url: '../../assets/img/dani/123.png'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
