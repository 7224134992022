import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  clicked = true;

  constructor() {}

  ngOnInit() {
    this.onShowMenu();
  }

  onShowMenu = () => {
    if (window.screen.width <= 768) {
      this.clicked = !this.clicked;
    }
  }

}
