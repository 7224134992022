import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BannerComponent } from './banner/banner.component';
import { PageContentComponent } from './page-content/page-content.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { NewsComponent } from './news/news.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryItemsComponent } from './gallery-items/gallery-items.component';
import { GalleryItemsActionComponent } from './gallery-items-action/gallery-items-action.component';
import { GalleryItemsMeetingsComponent } from './gallery-items-meetings/gallery-items-meetings.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    PageContentComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    NewsComponent,
    GalleryComponent,
    GalleryItemsComponent,
    GalleryItemsActionComponent,
    GalleryItemsMeetingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
