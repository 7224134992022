import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageContentComponent } from './page-content/page-content.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryItemsComponent } from './gallery-items/gallery-items.component';
import { GalleryItemsActionComponent } from './gallery-items-action/gallery-items-action.component';
import { GalleryItemsMeetingsComponent } from './gallery-items-meetings/gallery-items-meetings.component';

const routes: Routes = [
  {path: '', component: PageContentComponent },
  {path: 'o-nama', component: AboutComponent },
  {path: 'kontakt', component: ContactComponent },
  {path: 'vesti', component: NewsComponent },
  {path: 'galerija', component: GalleryComponent },
  {path: 'galerija/slike', component: GalleryItemsComponent },
  {path: 'galerija/radna-akcija', component: GalleryItemsActionComponent },
  {path: 'galerija/sastanci', component: GalleryItemsMeetingsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
